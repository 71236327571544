<template>
  <CRow>


    <CModal
        title="View Comment"
        size="lg"
        :show.sync="largeModal"
        :no-close-on-backdrop="true"
        :hide-footer="true"
        color="primary"
    >

      <CCardBody>

        <CCol md="12" class="align-self-center">
          <CRow>
            <CCol sm="6" md="4" lg="12">
              <p class="custome-labal">
                Comment :
              </p>
              <p class="custome-detail">
                {{ self.comment.comment }}
              </p>
            </CCol>

          </CRow>
        </CCol>

      </CCardBody>
    </CModal>


    <CCol col="12">
      <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>
        {{ err_msg }}
      </CAlert>
      <CCard>
        <CCardHeader>
          <back-to-list></back-to-list>
          <div class="row">
            <CCol sm="6">
              <strong> Group : {{ groupTitle }} </strong> <br>
              <profiler-detail :username="userName" :profilerId="userId"></profiler-detail>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton
                      color="danger"
                      v-c-tooltip="$lang.buttons.export.pdf"
                      v-on:click="exports(self, 'pdf', module, 'Comments',self.eventId)"
                  ><i class="fas fa-file-pdf"></i></CButton>
                  <VueJsonToCsv
                      class="btn btn-success"
                      v-c-tooltip="$lang.buttons.export.excel"
                      :json-data="json_data"
                      :labels="json_label"
                      :csv-title="json_title"
                  >
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <CAlert
              :color="messageColor"
              closeButton
              :show.sync="dismissCountDownS"
              fade
          >{{ alertMessage }}
          </CAlert
          >
          <v-server-table
              :columns="columns"
              :url="listUrl"
              :options="options"
              ref="myTable"
              @loaded="
                exports(self, 'Excel', module, 'Comments', self.eventId)
              "
          >
            <template #name="data">
              <Avatar
                  :image="data.row.avatar"
                  :content="data.row.name"
                  :isProfileListPage="true"
                  :length="20"
              />
            </template>
            <template #comment="data">
              <span :title="data.row.comment">
                    {{ trimfunction(data.row.comment, 50) }}
              </span>
            </template>
            <template #actions="data">
              <CButton
                  color="primary"
                  v-on:click="viewComment(data.row.id)"
              >
                <i class="fas fa-eye"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {gAC} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import VueNotifications from "vue-notifications";
import VueToasted from "vue-toasted";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import VueJsonToCsv from "vue-json-to-csv";
import BackToList from "../../backToList";
import Avatar from "/src/components/Avatar";
import ProfilerDetail from "../../ProfilerDetail";

function toast({title, message, type, timeout, cb}) {
  if (type === VueNotifications.types.warn) type = "show";
  return Vue.toasted[type](message, {
    duration: timeout,
    position: "top-left",
  });
}

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

Vue.use(VueToasted);
const optionss = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
  position: "top-center",
};
Vue.use(VueNotifications, optionss);
Vue.use(VueForm, options);
export default {
  name: "EventComments",
  mixins: [Mixin],
  components: {
    ProfilerDetail,
    VueJsonToCsv,
    BackToList,
    Avatar
  },
  data() {
    return {
      submitted: false,
      eventId: '',
      userName: '',
      userId: '',
      listUrl: '/users/groups/events/comment/list',
      json_data: [],
      messageColor: "success",
      json_label: {},
      json_title: "",
      toggleFilterValue: false,
      alertMessage: "",
      showAlert: false,
      dismissCountDownS: 0,
      msg: "",
      items: [],
      activePage: 1,
      selectedLetter: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      dismissCountDown: 0,
      statusOptions: [],
      self: this,
      module: gAC,
      comment: {
        comment: '',
      },
      maxlength: {
        // category: Maxlength.gac.name,
      },
      columns: ["name", "comment", "createdAt", "updatedAt", "actions"],
      data: [],
      options: {
        headings: {
          name: this.$lang.events.table.comments.name,
          comment: this.$lang.events.table.comments.comment,
          createdAt: this.$lang.events.table.comments.created,
          updatedAt: this.$lang.events.table.comments.updated,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["name"],
        sortable: ["name", "comment", "createdAt"],
        filterable: ["name", "comment", "updatedAt"],
        // see the options API
      },
    };
  },
  validations: {},


  created() {
    let self = this;
    self.dismissCountDown = 0;
    self.eventId = this.$route.params.event_id;
    if (this.$route.params.event_id) {
      self.dismissCountDown = 0;
      self.listUrl = self.listUrl + "/" + this.$route.params.event_id;
    }
    store.commit('showLoader', false); // Loader Start
    self.userId = localStorage.getItem('userId');
    self.userName = localStorage.getItem('userName');
    self.groupTitle = localStorage.getItem('groupTitle');
  },
  mounted() {
    store.commit('showLoader', false); // Loader Start
    let self = this;
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    // self.data = this.loadData();
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    /* get from child component */
    switchFilter(show) {
      this.toggleFilterValue = show;
    },

    createTableRow() {
      let self = this;
      self.$v.$reset();
      self.submitType = "Create";
      self.gac.name = "";
      self.gac.countryId = "";
      self.largeModal = true;
      self.gac.status = 1;
    },
    viewComment(id) {
      let self = this;
      axios.get('users/groups/events/comment/view/' + id).then((response) => {
        if (response) {
          let responseData = response.data.data;
          self.comment.comment = responseData.comment;
        } else {
          self.data = [];
        }
      });
      self.largeModal = true;
    },
  },
};
</script>
<style>
div .error {
  color: red;
}

.hasError label {
  color: red;
}
</style>
